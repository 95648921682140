import React from 'react'

import './styles.css'

const addZero = time => (time < 10 ? `0${time}` : time)

const getWeekDay = date => {
  const days = [
    'Sunnuntai',
    'Maanantai',
    'Tiistai',
    'Keskiviikko',
    'Torstai',
    'Perjantai',
    'Lauantai'
  ]
  return days[date.getDay()]
}

const getMonth = date => {
  const months = [
    'Tammikuu',
    'Helmikuu',
    'Maaliskuu',
    'Huhtikuu',
    'Toukokuu',
    'Kesäkuu',
    'Heinäkuu',
    'Elokuu',
    'Syyskuu',
    'Lokakuu',
    'Marraskuu',
    'Joulukuu'
  ]
  return months[date.getMonth()]
}

const Header = ({ date }) => {
  const hours = date.getHours()
  const minutes = date.getMinutes()
  const time = `${addZero(hours)} : ${addZero(minutes)}`

  const weekDay = getWeekDay(date)
  const monthDay = date.getDate()
  const month = getMonth(date)
  const fullYear = date.getFullYear()

  return (
    <div className="match-header">
      <div className="match-header__date">
        {weekDay} {monthDay}. {month} {fullYear}
      </div>

      <div className="match-header__time">
        <svg
          viewBox="0 0 480 289"
          xmlns="http://www.w3.org/2000/svg"
          className="match-header__whistle"
        >
          <path d="M472 .5H264a8 8 0 0 0-8 8v24h-40v-24a8 8 0 0 0-8-8h-64a143.506 143.506 0 0 0-70.45 18.473A39.899 39.899 0 0 0 32.403 1.21 39.891 39.891 0 0 0 .711 32.902a39.899 39.899 0 0 0 17.762 41.149C-10.297 125.25-5.09 188.785 31.625 234.617c36.715 45.836 97.582 64.781 153.824 47.883 56.242-16.898 96.586-66.258 101.957-124.738l186.489-45.485A8.005 8.005 0 0 0 480 104.5v-96a8 8 0 0 0-8-8zm-432 16a23.742 23.742 0 0 1 20.176 11.2 142.074 142.074 0 0 0-32.977 32.976A23.756 23.756 0 0 1 16 40.5c0-13.254 10.746-24 24-24zm424 81.719l-186.059 45.386a7.996 7.996 0 0 0-6.093 7.375c-3.536 69.758-62.344 123.801-132.153 121.446-69.808-2.356-124.84-60.242-123.668-130.078C17.203 72.508 74.152 16.508 144 16.5h56v24a8 8 0 0 0 8 8h56a8 8 0 0 0 8-8v-24h192v81.719z" />
          <path d="M144 56.5c-48.602 0-88 39.398-88 88s39.398 88 88 88 88-39.398 88-88c-.059-48.578-39.422-87.941-88-88zm0 160c-39.766 0-72-32.234-72-72s32.234-72 72-72c39.762 0 72 32.234 72 72-.05 39.742-32.258 71.95-72 72z" />
        </svg>

        {time}
      </div>
    </div>
  )
}

export default Header
