import React, { useState } from 'react'

import Section from '@components/section'

import Opponent from './opponent'
import Header from './header'
import Score from './score'

import matches from './mock/matches'

import './styles.css'

const Matches = ({ isNextMatchPage, title }) => {
  const [collapsed, setCollapse] = useState(true)

  const futureMatches = matches.filter(match => {
    const dateNow = new Date()
    const date = new Date(match.datetime.replace(/-/g, '/'))

    const nextDay = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() + 1
    )

    return dateNow < nextDay
  })

  const nextMatch = futureMatches.slice(-1)

  const getData = collapsed ? nextMatch : matches

  return (
    <Section title={title}>
      {getData.map((match, i) => {
        const date = new Date(match.datetime.replace(/-/g, '/'))

        const isHomeMatch = +match.is_home_match

        return (
          <div key={`match-${i}`} className="match">
            <Header date={date} />

            <div className="match-main">
              <Opponent data={isHomeMatch ? match.team : match.opponent} />
              <Score date={date} match={match} isHomeMatch={isHomeMatch} />
              <Opponent data={isHomeMatch ? match.opponent : match.team} />
            </div>
          </div>
        )
      })}

      {!isNextMatchPage && (
        <button
          type="button"
          onClick={() => setCollapse(!collapsed)}
          className="matches-collapse"
        >
          {collapsed ? 'Katso kaikki ottelut' : 'Sulje'}
        </button>
      )}
    </Section>
  )
}

export default Matches
