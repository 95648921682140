import React from 'react'
import classNames from 'classnames'

import './styles.css'

const Score = ({ date, match, isHomeMatch }) => {
  const dateNow = new Date()
  const isFinished = dateNow > date

  const score = isHomeMatch
    ? `${match.team.score} : ${match.opponent.score}`
    : `${match.opponent.score} : ${match.team.score}`

  return (
    <div className="match-score">
      <div
        className={classNames({
          'match-score__count': true,
          'match-score__count--future': !isFinished
        })}
      >
        {isFinished ? score : '- : -'}
      </div>
      {isFinished && <div className="match-score__text">Päättynyt</div>}
    </div>
  )
}

export default Score
