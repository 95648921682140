import React from 'react'

import './styles.css'

const Loader = () => (
  <div className="loader">
    <div className="loader__gravity">
      <div className="loader__ball" />
    </div>
    <div className="loader__shadow" />
  </div>
)

export default Loader
