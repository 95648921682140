import React from 'react'

import ContentWrapper from '@components/content-wrapper'

import './styles.css'

const Section = ({ children, title }) => (
  <section className="app-section">
    <ContentWrapper>
      <h2 className="app-section__title">{title}</h2>
      {children}
    </ContentWrapper>
  </section>
)

export default Section
