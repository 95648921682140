const statistics = [
  {
    RankingOrder: 1,
    TeamName: 'Ketterä',
    TeamID: 862621741,
    TeamGames: 8,
    TeamWins: 6,
    TeamOTWins: 1,
    TeamTies: 0,
    TeamOTLosses: 0,
    TeamLosses: 1,
    TeamGoalsFor: 32,
    TeamGoalsAgainst: 18,
    TeamPoints: 20,
    StandingLines: '6,10',
    PointRules: '3,2,0,1,0',
    Type: 'Regular'
  },
  {
    RankingOrder: 3,
    TeamName: 'FPS',
    TeamID: 393460951,
    TeamGames: 8,
    TeamWins: 5,
    TeamOTWins: 0,
    TeamTies: 0,
    TeamOTLosses: 1,
    TeamLosses: 2,
    TeamGoalsFor: 34,
    TeamGoalsAgainst: 26,
    TeamPoints: 16,
    StandingLines: '6,10',
    PointRules: '3,2,0,1,0',
    Type: 'Regular'
  },
  {
    RankingOrder: 4,
    TeamName: 'IPK',
    TeamID: 407724341,
    TeamGames: 8,
    TeamWins: 4,
    TeamOTWins: 2,
    TeamTies: 0,
    TeamOTLosses: 0,
    TeamLosses: 2,
    TeamGoalsFor: 24,
    TeamGoalsAgainst: 18,
    TeamPoints: 16,
    StandingLines: '6,10',
    PointRules: '3,2,0,1,0',
    Type: 'Regular'
  },
  {
    RankingOrder: 5,
    TeamName: 'KeuPa HT',
    TeamID: 405306364,
    TeamGames: 8,
    TeamWins: 5,
    TeamOTWins: 0,
    TeamTies: 0,
    TeamOTLosses: 1,
    TeamLosses: 2,
    TeamGoalsFor: 30,
    TeamGoalsAgainst: 25,
    TeamPoints: 16,
    StandingLines: '6,10',
    PointRules: '3,2,0,1,0',
    Type: 'Regular'
  },
  {
    RankingOrder: 6,
    TeamName: 'Hokki',
    TeamID: 562425130,
    TeamGames: 8,
    TeamWins: 3,
    TeamOTWins: 2,
    TeamTies: 0,
    TeamOTLosses: 0,
    TeamLosses: 3,
    TeamGoalsFor: 28,
    TeamGoalsAgainst: 23,
    TeamPoints: 13,
    StandingLines: '6,10',
    PointRules: '3,2,0,1,0',
    Type: 'Regular'
  },
  {
    RankingOrder: 7,
    TeamName: 'JoKP',
    TeamID: 425114685,
    TeamGames: 6,
    TeamWins: 3,
    TeamOTWins: 0,
    TeamTies: 0,
    TeamOTLosses: 0,
    TeamLosses: 3,
    TeamGoalsFor: 18,
    TeamGoalsAgainst: 18,
    TeamPoints: 9,
    StandingLines: '6,10',
    PointRules: '3,2,0,1,0',
    Type: 'Regular'
  },
  {
    RankingOrder: 8,
    TeamName: 'Peliitat',
    TeamID: 7568608,
    TeamGames: 9,
    TeamWins: 3,
    TeamOTWins: 2,
    TeamTies: 0,
    TeamOTLosses: 0,
    TeamLosses: 4,
    TeamGoalsFor: 24,
    TeamGoalsAgainst: 24,
    TeamPoints: 13,
    StandingLines: '6,10',
    PointRules: '3,2,0,1,0',
    Type: 'Regular'
  },
  {
    RankingOrder: 9,
    TeamName: 'SaPKo',
    TeamID: 69800122,
    TeamGames: 8,
    TeamWins: 3,
    TeamOTWins: 0,
    TeamTies: 0,
    TeamOTLosses: 1,
    TeamLosses: 4,
    TeamGoalsFor: 18,
    TeamGoalsAgainst: 20,
    TeamPoints: 10,
    StandingLines: '6,10',
    PointRules: '3,2,0,1,0',
    Type: 'Regular'
  },
  {
    RankingOrder: 10,
    TeamName: 'RoKi',
    TeamID: 253541164,
    TeamGames: 8,
    TeamWins: 2,
    TeamOTWins: 1,
    TeamTies: 0,
    TeamOTLosses: 1,
    TeamLosses: 4,
    TeamGoalsFor: 24,
    TeamGoalsAgainst: 33,
    TeamPoints: 9,
    StandingLines: '6,10',
    PointRules: '3,2,0,1,0',
    Type: 'Regular'
  },
  {
    RankingOrder: 11,
    TeamName: 'Hermes',
    TeamID: 1252754856,
    TeamGames: 8,
    TeamWins: 2,
    TeamOTWins: 1,
    TeamTies: 0,
    TeamOTLosses: 0,
    TeamLosses: 5,
    TeamGoalsFor: 22,
    TeamGoalsAgainst: 25,
    TeamPoints: 8,
    StandingLines: '6,10',
    PointRules: '3,2,0,1,0',
    Type: 'Regular'
  },
  {
    RankingOrder: 12,
    TeamName: 'TUTO Hockey',
    TeamID: 765635173,
    TeamGames: 7,
    TeamWins: 2,
    TeamOTWins: 0,
    TeamTies: 0,
    TeamOTLosses: 1,
    TeamLosses: 4,
    TeamGoalsFor: 19,
    TeamGoalsAgainst: 23,
    TeamPoints: 7,
    StandingLines: '6,10',
    PointRules: '3,2,0,1,0',
    Type: 'Regular'
  },
  {
    RankingOrder: 13,
    TeamName: 'KOOVEE',
    TeamID: 901495274,
    TeamGames: 8,
    TeamWins: 2,
    TeamOTWins: 0,
    TeamTies: 0,
    TeamOTLosses: 2,
    TeamLosses: 4,
    TeamGoalsFor: 25,
    TeamGoalsAgainst: 35,
    TeamPoints: 8,
    StandingLines: '6,10',
    PointRules: '3,2,0,1,0',
    Type: 'Regular'
  },
  {
    RankingOrder: 14,
    TeamName: 'K-Vantaa',
    TeamID: 928640177,
    TeamGames: 9,
    TeamWins: 1,
    TeamOTWins: 0,
    TeamTies: 0,
    TeamOTLosses: 2,
    TeamLosses: 6,
    TeamGoalsFor: 17,
    TeamGoalsAgainst: 36,
    TeamPoints: 5,
    StandingLines: '6,10',
    PointRules: '3,2,0,1,0',
    Type: 'Regular'
  }
]

export default statistics
