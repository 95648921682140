import React from 'react'
import PropTypes from 'prop-types'

import './reset.css'
import './layout.css'

const Layout = ({ children }) => {
  return (
    <div className="app-wrapper">
      <main className="app-main">{children}</main>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
