import React from 'react'
import classNames from 'classnames'

import Loader from '@components/loader'
import Section from '@components/section'

import statistics from './mock/statistics'
import matches from '@components/matches/mock/matches'

import './styles.css'

const Statistics = ({ isLoading }) => {
  matches.forEach(match =>
    statistics.find(e => {
      if (e.TeamName === match.opponent.name) {
        e.Logo = match.opponent.logo
      }
      if (e.TeamName === match.team.name) {
        e.Logo = match.team.logo
      }
    })
  )

  return (
    <Section title="Sarjataulukko">
      <div className="stat-table">
        <div className="table-header">
          <TableRow children="Sij." width={50} isSticky />
          <TableRow
            children="Joukkue"
            width={180}
            minWidth={58}
            isSeparated
            isSticky
            left={50}
          />
          <TableRow children="P" />
          <TableRow children="O" />
          <TableRow children="V" />
          <TableRow children="T" />
          <TableRow children="H" />
          <TableRow children="LP" />
          <TableRow children="TM" />
          <TableRow children="PM" />
        </div>

        {isLoading && <Loader />}

        {!isLoading &&
          statistics.map(team => {
            return (
              <div className="table-row" key={team.TeamID}>
                <TableRow children={team.RankingOrder} width={50} isSticky />
                <TableRow
                  children={
                    <TeamLogoName logo={team.Logo} name={team.TeamName} />
                  }
                  width={180}
                  minWidth={58}
                  isSeparated
                  isSticky
                  left={50}
                />
                <TableRow children={team.TeamPoints} isBoldFont />
                <TableRow children={team.TeamGames} />
                <TableRow children={team.TeamWins} />
                <TableRow
                  children={team.TeamGames - team.TeamWins - team.TeamLosses}
                />
                <TableRow children={team.TeamLosses} />
                <TableRow children={team.TeamOTWins} />
                <TableRow children={team.TeamGoalsFor} />
                <TableRow children={team.TeamGoalsAgainst} />
              </div>
            )
          })}
      </div>
    </Section>
  )
}

const TeamLogoName = ({ logo, name }) => (
  <div className="stat-logo-name">
    {logo && <img src={logo} alt={name} className="stat-logo-name__img" />}
    {name}
  </div>
)

const TableRow = ({
  children,
  width,
  isBoldFont,
  isSticky,
  isSeparated,
  left
}) => (
  <div
    className={classNames({
      'table-row__item': true,
      'table-row__item--bold': isBoldFont,
      'table-row__item--sticky': isSticky,
      'table-row__item--separated': isSeparated
    })}
    style={{
      width: width || 54,
      maxWidth: width,
      left
    }}
  >
    {children}
  </div>
)

export default Statistics
